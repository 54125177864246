import React, { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";
import cookieMdUrl from '../blog/cookie-policy.md';
import privacyMdUrl from '../blog/privacy-policy.md';

function Policies() {
  const [mdCookiePolicy, setMdCookiePolicy] = useState<string>("");
  const [mdPrivacyPolicy, setMdPrivacyPolicy] = useState<string>("");

  useEffect(() => {
    fetch(privacyMdUrl)
      .then((res) => res.text())
      .then((text) => setMdPrivacyPolicy(text));
  }, []);

  useEffect(() => {
    fetch(cookieMdUrl)
      .then((res) => res.text())
      .then((text) => setMdCookiePolicy(text));
  }, []);

  return (
    <div className="container">
      <div className="row col-lg-10">
        {mdPrivacyPolicy && (<Markdown>{mdPrivacyPolicy}</Markdown>)}
        {mdCookiePolicy && (<Markdown>{mdCookiePolicy}</Markdown>)}
      </div>
    </div>
  );

}

export default Policies;