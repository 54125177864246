import React, { useEffect } from "react";

function Contact() {
  useEffect(() => {
    window.location.href = "https://linkedin.com/in/blakewatts";
  }, []);

  return (
    (<><h1>Redirecting...</h1></>)
  );
}

export default Contact;
