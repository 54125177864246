import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Markdown from "markdown-to-jsx";
import slugify from "slugify";
import blogIndex from "../blog/index.json";

function Blog() {
  let params = useParams();
  let blogName = params.blogName;

  const [markdownContent, setMarkdownContent] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [record, setRecord] = useState<any>(undefined);
  const navigate = useNavigate();

  /* Runs when fileName changes */
  useEffect(() => {
    if (fileName) {
      fetch(fileName)
        .then((res) => res.text())
        .then((text) => setMarkdownContent(text));
    } else if (!record) {
      navigate("/notfound/", { replace: true });
    }
  }, [fileName, navigate, record]);

  /* runs when record is set */
  useEffect(() => {
    if (!fileName && record) {
      let resolvedFileName = require("../blog/" + record?.mdFileName);
      if (resolvedFileName) {
        setFileName(resolvedFileName);
      }
    }
  }, [record, fileName]);

  /* Find the blog metadata based on the slugified value passed-in */
  if (record === undefined) {
    let found = blogIndex.find(
      (e) => slugify(e.title.toLowerCase()) === blogName
    );
    if (found) {
      setRecord(found);
    }
  }

  return (
    <div className="container">
      <div className="row col-lg-10">
        {record && (
          <>
            <h2>{record.title}</h2>
            <p className="text-muted">Published: {record.date}</p>
          </>
        )}
        {markdownContent && <Markdown>{markdownContent}</Markdown>}
        {!fileName && <h1>Loading...</h1>}
      </div>
    </div>
  );
}

export default Blog;
