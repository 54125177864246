import React from "react";
import { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import MenuCtrl from "./components/MenuCtrl";
import FooterCtrl from "./components/FooterCtrl";
import NotFound from "./pages/NotFound";
import Policies from "./pages/Policies";
import Contact from "./pages/Contact";
import "./App.css";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
library.add(faLinkedin, faTwitter)

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <MenuCtrl />
        <Routes>
          <Route path="/namedpipepaper.html" element={<Navigate to="/blog/discovering-and-exploiting-named-pipe-security-flaws-for-fun-and-profit" />} />
          <Route path="/blog/:blogName" element={<Blog />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/notfound" element={<NotFound />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={ <Navigate to="/notfound" /> } />
        </Routes>
        <FooterCtrl />
      </BrowserRouter>
    </div>
  );
}

export default App;
