import React from "react";
import { Link } from "react-router-dom";
import blogIndex from "../blog/index.json";
import patentIndex from "../blog/patents.json";
import slugify from "slugify";

function Home() {
  return (
    <>
      <div className="container">
        <div className="row col-lg-10">
          <h4 className="bottom-border">About</h4>
          <p>
            Blake Watts is a windows internals expert with specialization in
            reverse engineering, virtualization, security and software
            development. He has been published in numerous vulnerability
            advisories, books, and papers on Windows security. He has spent the
            last two decades creating software for startups and
            large companies, including Foundstone, McAfee, AirWatch, and VMware.
          </p>

          <h4 className="bottom-border">Published papers and books</h4>
          <p>
            This section contains a subset of the books and papers I've authored
            or contributed to over the years.
          </p>
          <ul>
            <li>
              [April 2002]{" "}
              <Link to={"/blog/" + slugify(blogIndex[0].title.toLowerCase())}>
                {blogIndex[0].title}
              </Link>
              <p><small>{blogIndex[0].summary}</small></p>
            </li>
            <li>
              [April 2005, Syngress]{" "}
              <a href="https://www.amazon.com/Sockets-Shellcode-Porting-Coding-Professionals/dp/1597490059">
                Sockets, Shellcode, Porting &amp; Coding, Syngress Publishing{" "}
                <i>(contributing author)</i>
              </a>
            </li>
          </ul>

          <h4 className="bottom-border">Vulnerability Advisories</h4>
          <p>
            This section contains a subset of the advisories that have made
            public over the years.
          </p>
          <ul>
            <li>
              <a href="https://docs.microsoft.com/en-us/security-updates/SecurityBulletins/2002/ms02-032">
                MS02-032: Windows Media Player WMDM Privilege Escalation
                Vulnerability
              </a>
            </li>
            <li>
              Denial of Service Vulnerability in Windows 2000 RunAs Service -
              RADIX1112200103
            </li>
            <li>
              Microsoft Windows 2000 RunAs User Credentials Exposure
              Vulnerability - RADIX1112200102
            </li>
            <li>
              Microsoft Windows 2000 RunAs Service Named Pipe Hijacking
              Vulnerability - RADIX1112200101
            </li>
            <li>
              <a href="https://docs.microsoft.com/en-us/security-updates/SecurityBulletins/2001/ms01-013">
                MS01-013: Event Viewer Buffer Overflow
              </a>
            </li>
            <li>
              <a href="https://docs.microsoft.com/en-us/security-updates/SecurityBulletins/2001/ms01-031">
                MS01-031: Telnet Service Privilege Escalation
              </a>
            </li>
            <li>
              <a href="https://docs.microsoft.com/en-us/security-updates/SecurityBulletins/2000/ms00-053">
                MS00-053: Service Control Manager Named Pipe Privilege
                Escalation
              </a>
            </li>
          </ul>

          <h4 className="bottom-border">Patents</h4>
          <ul>
            {patentIndex.sort((a, b) => new Date(a.filed).getTime() - new Date(b.filed).getTime()).reverse().map((e) => (
              <li>
                <div>
                  <a href={"https://patents.google.com/patent/US" + e.number}>
                     {e.title} ({e.number})
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Home;
