import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*
                 <Nav>
                <Nav.Link href="https://www.twitter.com/blakewattsdev">
                  <FontAwesomeIcon icon={["fab", "twitter"]}  className="mx-2" />
                  Follow
                </Nav.Link>
              </Nav>
*/

class Menu extends Component {
  render() {
    return (
      <div className="container-fluid p-0 m-0">
        <Navbar bg="dark" variant="dark" expand="sm">
          <Container>
            <Navbar.Brand>
              <Link
                className="navbar-brand"
                to={`/`}
                style={{ textTransform: "uppercase", letterSpacing: "3px" }}
              >
                blake watts
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
              <Nav>
                <Nav.Link href="https://www.linkedin.com/in/blakewatts">
                  <FontAwesomeIcon icon={["fab", "linkedin"]}  className="mx-2" />
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default Menu;
